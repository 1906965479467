var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Dialog',{staticClass:"p-fluid",attrs:{"visible":_vm.visible,"header":_vm.exibirHeader(),"closeOnEscape":!_vm.saving && !_vm.confirmDialog,"closable":!_vm.saving,"modal":true,"containerStyle":{ width: '70vw' }},on:{"show":function($event){return _vm.onShow()},"hide":function($event){return _vm.onHide()},"update:visible":function($event){return _vm.fechar()}},scopedSlots:_vm._u([{key:"footer",fn:function(){return [_c('Button',{staticClass:"p-button-text",attrs:{"disabled":_vm.saving,"label":"Cancelar","icon":"pi pi-times"},on:{"click":function($event){return _vm.fechar()}}}),_c('Button',{attrs:{"disabled":_vm.saving,"autofocus":""},on:{"click":_vm.validate}},[(!_vm.saving)?_c('span',{staticClass:"pi pi-check"}):_vm._e(),(_vm.saving)?_c('span',{staticClass:"pi pi-spin pi-spinner"}):_vm._e(),(!_vm.saving)?_c('span',{staticClass:"ml-2"},[_vm._v("Salvar")]):_vm._e(),(_vm.saving)?_c('span',{staticClass:"ml-2"},[_vm._v("Aguarde")]):_vm._e()])]},proxy:true}])},[_c('BlockUI',{attrs:{"blocked":_vm.saving}},[_c('div',{staticClass:"grid"},[_c('div',{staticClass:"col-12 md:col-12"},[_c('div',{staticClass:"card"},[_c('h5',[_vm._v("Nova Atividade")]),_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.validate()}}},[_c('div',{staticClass:"p-fluid formgrid grid"},[_c('div',{staticClass:"field col-12 md:col-6"},[_c('label',{attrs:{"for":"nome"}},[_vm._v("Nome")]),_c('InputText',{class:{
                    'p-invalid': _vm.submitted && _vm.v$.atividade.nome.$invalid,
                  },attrs:{"id":"nome","type":"text"},model:{value:(_vm.atividade.nome),callback:function ($$v) {_vm.$set(_vm.atividade, "nome", $$v)},expression:"atividade.nome"}}),(_vm.submitted && _vm.v$.atividade.nome.required.$invalid)?_c('div',{staticClass:"p-error"},[_vm._v(" O campo Nome é obrigatório. ")]):_vm._e()],1),_c('div',{staticClass:"field col-12 md:col-6"},[_c('label',{attrs:{"for":"coluna_margem"}},[_vm._v("Coluna Margem")]),_c('InputNumber',{class:{
                    'p-invalid':
                      _vm.submitted && _vm.v$.atividade.colunaMargem.$invalid,
                  },attrs:{"id":"colunaMargem"},model:{value:(_vm.atividade.colunaMargem),callback:function ($$v) {_vm.$set(_vm.atividade, "colunaMargem", $$v)},expression:"atividade.colunaMargem"}}),(
                    _vm.submitted && _vm.v$.atividade.colunaMargem.required.$invalid
                  )?_c('div',{staticClass:"p-error"},[_vm._v(" O campo Coluna Margem é obrigatório. ")]):_vm._e()],1)]),(_vm.id)?_c('div',[_c('div',[_vm._v("Status")]),_c('div',[_c('InputSwitch',{model:{value:(_vm.atividade.ativo),callback:function ($$v) {_vm.$set(_vm.atividade, "ativo", $$v)},expression:"atividade.ativo"}})],1)]):_vm._e()])])])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }