export default class Atividade {
  constructor(
    id = null,
    nome = null,
    colunaMargem = null,
    ativo = true,
    alteradoPor = null,
    cadastradoPor = null,
  ) {
    this.id = id
    this.nome = nome
    this.colunaMargem = colunaMargem
    this.ativo = ativo
    this.alteradoPor = alteradoPor
    this.cadastradoPor = cadastradoPor
  }
}
