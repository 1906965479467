<template>
  <Dialog
    :visible="visible"
    :header="exibirHeader()"
    :closeOnEscape="!saving && !confirmDialog"
    :closable="!saving"
    :modal="true"
    :containerStyle="{ width: '70vw' }"
    class="p-fluid"
    @show="onShow()"
    @hide="onHide()"
    @update:visible="fechar()">
    <BlockUI :blocked="saving">
      <div class="grid">
        <div class="col-12 md:col-12">
          <div class="card">
            <h5>Nova Atividade</h5>
            <form @submit.prevent="validate()">
              <div class="p-fluid formgrid grid">
                <div class="field col-12 md:col-6">
                  <label for="nome">Nome</label>
                  <InputText
                    id="nome"
                    v-model="atividade.nome"
                    type="text"
                    :class="{
                      'p-invalid': submitted && v$.atividade.nome.$invalid,
                    }" />
                  <div
                    v-if="submitted && v$.atividade.nome.required.$invalid"
                    class="p-error">
                    O campo Nome é obrigatório.
                  </div>
                </div>
                <div class="field col-12 md:col-6">
                  <label for="coluna_margem">Coluna Margem</label>
                  <InputNumber
                    id="colunaMargem"
                    v-model="atividade.colunaMargem"
                    :class="{
                      'p-invalid':
                        submitted && v$.atividade.colunaMargem.$invalid,
                    }" />
                  <div
                    v-if="
                      submitted && v$.atividade.colunaMargem.required.$invalid
                    "
                    class="p-error">
                    O campo Coluna Margem é obrigatório.
                  </div>
                </div>
              </div>
              <div v-if="id">
                <div>Status</div>
                <div>
                  <InputSwitch v-model="atividade.ativo" />
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </BlockUI>
    <template #footer>
      <Button
        :disabled="saving"
        label="Cancelar"
        icon="pi pi-times"
        class="p-button-text"
        @click="fechar()" />
      <Button :disabled="saving" autofocus @click="validate">
        <span v-if="!saving" class="pi pi-check"></span>
        <span v-if="saving" class="pi pi-spin pi-spinner"></span>
        <span v-if="!saving" class="ml-2">Salvar</span>
        <span v-if="saving" class="ml-2">Aguarde</span>
      </Button>
    </template>
  </Dialog>
</template>

<script>
import UseVuelidate from '@vuelidate/core'
import { required } from '@vuelidate/validators'
import Atividade from '@/domain/Atividade.js'
import AtividadeService from '@/service/AtividadeService.js'

export default {
  props: {
    visible: { type: Boolean, required: true },
    update: { type: Boolean, required: true },
    propsatividade: {
      type: Object,
      required: false,
      default: new Atividade(),
    },
  },
  setup() {
    return { v$: UseVuelidate() }
  },

  data() {
    return {
      atividade: new Atividade(),
      id: this.$route.params.id,
      submitted: false,
      confirmDialog: false,
      submitStatus: null,
      saving: false,
      alteradoPor: {
        id: 0,
      },
      cadastradoPor: {
        id: 0,
      },
    }
  },

  validations() {
    return {
      atividade: {
        nome: { required },
        colunaMargem: { required },
      },
    }
  },

  created() {
    this.service = new AtividadeService(this.$http)
    if (this.id) {
      this.service.getAtividade(this.id).then((res) => {
        this.atividade = res
      })
    }
  },

  methods: {
    exibirHeader() {
      return this.update ? 'Editar Atividade' : 'Nova Atividade'
    },

    fechar() {
      this.$emit('update:visible', false)
    },

    onShow() {
      this.atividade = JSON.parse(JSON.stringify(this.propsatividade))
      this.id = this.atividade?.id
      if (!this.id) {
        this.atividade.ativo = false
      }
    },

    onHide() {},
    validate() {
      this.submitted = true
      this.v$.atividade.$touch()

      if (this.v$.atividade.$invalid) {
        this.submitStatus = 'error'
        return
      } else {
        this.submitStatus = 'PENDING'
        this.salvar()
        setTimeout(() => {
          this.submitStatus = 'OK'
        }, 500)
      }
    },

    salvar() {
      this.saving = true
      this.atividade.alteradoPor = this.alteradoPor
      this.atividade.cadastradoPor = this.cadastradoPor
      this.service
        .saveAtividade(this.atividade)
        .then(
          () => {
            this.submitted = false
            this.v$.$reset()
            this.atividade = new Atividade()
            this.exibeToast()
          },
          (err) => {
            console.log(err)
          },
        )
        .finally(() => {
          this.saving = false
          this.$emit('carregarDados')
          this.fechar()
        })
    },

    exibeToast() {
      if (this.id) {
        this.$toast.add({
          severity: 'success',
          summary: 'Atualizado com sucesso!',
          life: 10000,
        })
      } else {
        this.$toast.add({
          severity: 'success',
          summary: 'Cadastrado com sucesso!',
          life: 10000,
        })
      }
    },
  },
}
</script>

<style></style>
